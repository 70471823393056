<template>
  <div id="notes">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">Notes Management</span>
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>

    <v-card>
      <!-- Notes Filter fields start -->
      <v-row class="pt-4 pb-0 ma-0">
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqType"
            :items="typeItems"
            item-text="text"
            item-value="value"
            label="Type"
            placeholder="Type"
            outlined
            hide-details
            clearable
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqType')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="sqIdentifier"
            label="Identifier"
            outlined
            hide-details
            clearable
            persistent-clear
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqIdentifier')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqPriority"
            :items="priorityItems"
            item-text="text"
            item-value="value"
            label="Priority"
            placeholder="Priority"
            outlined
            hide-details
            clearable
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqPriority')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqStatus"
            :items="statusItems"
            item-text="text"
            item-value="value"
            label="Status"
            placeholder="Status"
            outlined
            hide-details
            clearable
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqStatus')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="sqAssigned"
            label="Assigned To"
            outlined
            hide-details
            clearable
            persistent-clear
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqAssigned')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
            v-model="menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sqCreated"
                label="Date"
                readonly
                v-bind="attrs"
                outlined
                hide-details
                dense
                clearable
                class="user-search me-3"
                v-on="on"
                @change="val => addToSearchHistory(val, 'sqCreated')"
              ></v-text-field>
            </template>
            <v-date-picker v-model="sqCreated" no-title @input="menu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- Notes Filter fields end -->

      <!-- Notes Table start (NOTE: DO NOT disable must-sort, will break custom sort) -->
      <v-data-table
        :headers="noteHeaders"
        :items="filteredNotes"
        :sort-by.sync="noteSortBy"
        :sort-desc.sync="noteSortDesc"
        :custom-sort="customSort"
        class="font-weight-semibold text--primary"
        :must-sort="true"
      >
        <template v-slot:item.note_id="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.note_id }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.title="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.title }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.type="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.type }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.formattedIdentifier="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary" v-html="formattedIdentifier(item)"></span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.priority="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.priority }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.status="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.status }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.claimant="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.claimant }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.assigned="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary">{{ item.assigned }}</span>
              </div>
            </div>
          </router-link>
        </template>

        <template v-slot:item.convertCreatedDate="{ item }">
          <router-link
            :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
            class="cursor-pointer text-decoration-none text-no-wrap"
          >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <span class="font-weight-semibold text--primary" v-html="convertCreatedDate(item)"></span>
              </div>
            </div>
          </router-link>
        </template>
      </v-data-table>
      <!-- Notes Table end -->

      <!-- Add Note Button, Modal and Form start -->
      <v-row class="pt-8 pl-5 pb-6 ma-0">
        <v-btn color="primary" elevation="0" @click="openDialog">Add Note</v-btn>
      </v-row>

      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-card-title class="d-flex">
            <span class="headline flex-grow-1">Add Note</span>

            <v-btn color="secondary" outlined elevation="0" @click="closeDialog">Close</v-btn>
          </v-card-title>
          <v-card-text>
            <v-form lazy-validation @submit.prevent="() => {}">
              <v-select
                v-model="formData.selectedType"
                :items="typeOptions"
                label="Select Type *"
                @change="changeForeignItems"
              ></v-select>

              <v-combobox
                v-if="formData.selectedType"
                v-model="formData.selectedForeignId"
                clearable
                label="Select Item"
                :items="foreignItems"
                item-text="title"
              ></v-combobox>

              <v-text-field v-model="formData.title" label="Title *"></v-text-field>
              <v-textarea v-model="formData.comments" label="Comments *"></v-textarea>
              <v-select v-model="formData.selectedPriority" :items="priorityOptions" label="Select Priority *">
              </v-select>
              <v-select v-model="formData.selectedStatus" :items="statusOptions" label="Select Status *"></v-select>
              <v-text-field v-model="formData.claimant" label="Claimant *"></v-text-field>
              <v-text-field v-model="formData.assigned" label="Assigned To (optional)"></v-text-field>
              <v-text-field v-model="formData.location" label="Location (optional)"></v-text-field>
              <v-btn class="mt-7" color="primary" :disabled="note_cannotSave" @click="addNote()"> Add </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Add Note Button, Modal and Form end -->
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import urlHelpers from "@/mixins/urlHelpers"
import store from "@/store"
import globalHelpers from "@/mixins/globalHelpers"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "Notes",
  metaInfo: {
    title: "Notes",
  },
  mixins: [urlHelpers, globalHelpers],

  data() {
    return {
      sqCreated: "",
      sqClaimant: "",
      sqAssigned: "",
      sqIdentifier: "",
      sqType: null,
      sqPriority: null,
      sqStatus: null,
      noteSortBy: "note_id",
      noteSortDesc: false, // true for descending order, false for ascending order
      menu: false,
      // Notes Table Headers
      noteHeaders: [
        {
          text: "Note ID",
          value: "note_id",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          sortable: true,
        },
        {
          text: "Identifier",
          value: "formattedIdentifier",
          sortable: true,
        },
        {
          text: "Priority",
          value: "priority",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
        },
        {
          text: "Claimant",
          value: "claimant",
          sortable: true,
        },
        {
          text: "Assigned To",
          value: "assigned",
          sortable: true,
        },
        {
          text: "Created",
          value: "convertCreatedDate",
          sortable: true,
        },
      ],
      typeItems: [
        {
          text: "Device",
          value: "Device",
        },
        {
          text: "Route",
          value: "Route",
        },
        {
          text: "Stop",
          value: "Stop",
        },
      ],
      priorityItems: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
      ],
      statusItems: [
        {
          text: "Open",
          value: "Open",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "On Hold",
          value: "On Hold",
        },
        {
          text: "Closed",
          value: "Closed",
        },
      ],
      formData: {
        selectedType: null,
        selectedForeignId: null,
        title: "",
        comments: "",
        selectedPriority: null,
        selectedStatus: null,
        claimant: "",
        assigned: "",
        location: "",
      },
      dialog: false,
      foreignItems: [],
      notesLiveUpdate: null,
    }
  },

  computed: {
    ...mapState({
      devices: state => state.gtfs.devices,
      stops: state => state.gtfs.stops,
      routes: state => state.gtfs.routes,
      notes: state => state.gtfs.notes,
    }),

    filteredNotes() {
      return this.notes.filter(note => {
        let include = true

        if (include && this.sqIdentifier !== "" && this.sqIdentifier !== null) {
          include = this.formattedIdentifier(note)?.toLowerCase().includes(this.sqIdentifier.toLowerCase())
        }

        if (include && this.sqCreated !== "" && this.sqCreated !== null) {
          // eslint-disable-next-line no-undef
          const date = dayjs(this.formatDate(note.created), "YYYY-MM-DD, h:mm:ss a")
          include = date.format("YYYY-MM-DD") === this.sqCreated
        }

        if (include && this.sqClaimant !== "" && this.sqClaimant !== null) {
          include = note.claimant?.toLowerCase().includes(this.sqClaimant.toLowerCase())
        }

        if (include && this.sqAssigned !== "" && this.sqAssigned !== null) {
          include = note.assigned?.toLowerCase().includes(this.sqAssigned.toLowerCase())
        }

        if (include && this.sqType !== "" && this.sqType !== null) {
          include = note.type === this.sqType
        }

        if (include && this.sqPriority !== "" && this.sqPriority !== null) {
          include = note.priority === this.sqPriority
        }

        if (include && this.sqStatus !== "" && this.sqStatus !== null) {
          include = note.status === this.sqStatus
        }

        return include
      })
    },

    convertCreatedDate() {
      return function (item) {
        return item.created ? this.formatDate(item.created) : "Unknown"
      }
    },

    formattedIdentifier() {
      return function (item) {
        const output = item.foreign_id && item.type ? this.identifierTitle(item.foreign_id, item.type) : "Unknown"

        return output
      }
    },
    typeOptions() {
      return [
        { text: "Vehicle / Device", value: "Device" },
        { text: "Route", value: "Route" },
        { text: "Stop", value: "Stop" },
      ]
    },
    priorityOptions() {
      return ["Low", "Medium", "High", "Urgent"]
    },
    statusOptions() {
      return ["Open", "Pending", "On Hold", "Closed"]
    },
    note_cannotSave() {
      return (
        !this.formData.selectedType ||
        !this.formData.selectedForeignId ||
        !this.formData.title ||
        !this.formData.comments ||
        !this.formData.selectedPriority ||
        !this.formData.selectedStatus ||
        !this.formData.claimant
      )
    },
  },

  created() {
    this.is_loading = true
    store
      .dispatch("getNotes", {
        type: null,
        foreign_id: null,
      })
      .then(() => {
        this.is_loading = false
      })
  },

  methods: {
    /**
     * Overrides default sorting by table headers.
     */
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = null
        let sortB = null

        // Handle sorting based on clicked header column
        switch (sortByField) {
          case "convertCreatedDate": {
            // We don't want to use convertCreatedDate as it can return "Unknown"
            sortA = new Date(a?.created)
            sortB = new Date(b?.created)

            break
          }
          case "formattedIdentifier": {
            // Convert item to formattedIdentifier string
            sortA = this.formattedIdentifier(a) ?? ""
            sortB = this.formattedIdentifier(b) ?? ""

            break
          }
          case "priority": {
            // Order by priority not alphabetically
            const priorityOrder = {
              urgent: 1,
              high: 2,
              medium: 3,
              low: 4,
            }

            sortA = priorityOrder[a.priority?.toLowerCase()] ?? 5
            sortB = priorityOrder[b.priority?.toLowerCase()] ?? 5

            break
          }
          default: {
            // Default sorting
            sortA = a[sortBy]
            sortB = b[sortBy]

            break
          }
        }

        if (sortA < sortB) {
          return -1
        }

        if (sortA > sortB) {
          return 1
        }

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
    identifierTitle(id, type) {
      switch (type) {
        case "Device":
          return this.getDeviceAndVehicleTitle(id)
        case "Route":
          return this.getRouteTitle(id)
        case "Stop":
          return this.getStopTitle(id)
        default:
          return "Unknown"
      }
    },
    getVehicleId(deviceId) {
      const device = this.devices.find(d => d.device_id === deviceId)

      return device && device.config.vehicle_id ? device.config.vehicle_id : null
    },
    getDeviceAndVehicleTitle(deviceId) {
      const deviceID = deviceId || deviceId.id
      const vehicleId = this.getVehicleId(deviceId)

      if (deviceID) {
        return vehicleId ? `Vehicle: ${vehicleId}<br />Device: ${deviceId}` : `Device ID: ${deviceId}`
      }

      return "Unknown"
    },
    getRouteTitle(routeId) {
      const route = this.routes.find(r => r.route_id === parseInt(routeId, 10))

      return route && route.route_short_name && route.route_long_name
        ? `${route.route_short_name} - ${route.route_long_name}`
        : "Unknown"
    },
    getStopTitle(stopId) {
      const stop = this.stops.find(s => s.stop_id === stopId)

      return stop && stop.stop_code && stop.stop_name ? `${stop.stop_code} - ${stop.stop_name}` : "Unknown"
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    async addNote() {
      try {
        const { selectedType, selectedForeignId, title, comments, selectedPriority, selectedStatus, claimant } =
          this.formData

        // if fields filled out
        if (
          selectedType &&
          selectedForeignId &&
          title.trim() !== "" &&
          comments.trim() !== "" &&
          selectedPriority &&
          selectedStatus !== null &&
          claimant.trim() !== ""
        ) {
          await this.$store.dispatch("addNote", { formData: this.formData })

          store.commit("SET_SNACKBAR", {
            text: `The note ${title} on ${selectedForeignId.title} has been added`,
            color: "success",
          })

          // Reset the form fields after successful submission
          this.formData = {
            selectedType: null,
            selectedForeignId: null,
            title: "",
            comments: "",
            selectedPriority: null,
            selectedStatus: null,
            claimant: "",
            assigned: "",
            location: "",
          }

          this.dialog = false
        }
      } catch (error) {
        console.error("error:", error)
        store.commit("SET_SNACKBAR", {
          text: `The note ${this.formData.title} on ${this.formData.selectedForeignId.title} could not be added`,
          color: "error",
        })
      }
    },
    changeForeignItems() {
      this.formData.selectedForeignId = null

      const type = this.formData.selectedType
      if (type === "Device") {
        this.foreignItems = this.devices.map(device => ({
          title: this.identifierTitle(device.device_id, this.formData.selectedType),
          value: device.device_id,
        }))
      } else if (type === "Route") {
        this.foreignItems = this.routes.map(route => ({
          title: this.identifierTitle(route.route_id, this.formData.selectedType),
          value: route.route_id,
        }))
      } else if (type === "Stop") {
        this.foreignItems = this.stops.map(stop => ({
          title: this.identifierTitle(stop.stop_id, this.formData.selectedType),
          value: stop.stop_id,
        }))
      }
    },
  },
}
</script>

<style scoped></style>
